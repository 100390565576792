<template>
  <v-container class="ticket-container">
    <!--<CategoryTitle :category="category" />-->

    <div class="ticket-form-container grey lighten-3 pa-3 rounded">
      <v-form
        ref="formRef"
        v-model="valid"
        lazy-validation
        @submit.prevent.stop="handleSubmit"
      >
        <h2>{{ $t("ticket.warningDetail.title") }}</h2>

        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              dense
              outlined
              v-model="serviceTypeSelected"
              :items="serviceTypeList"
              return-object
              required
              item-text="description"
              background-color="white"
              :label="$t('ticket.warningDetail.serviceType')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              dense
              outlined
              v-model="communicationTypeSelected"
              :items="communicationTypeList"
              return-object
              required
              background-color="white"
              item-text="description"
              :label="$t('ticket.warningDetail.communication')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              outlined
              dense
              v-model="ticketTypesSelected"
              :items="ticketTypesList"
              return-object
              background-color="white"
              required
              item-text="description"
              :label="$t('ticket.warningDetail.ticketType')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-file-input
              outlined
              background-color="white"
              dense
              v-model="attachmentModel"
              accept="image/jpeg,image/gif,image/png,application/pdf"
              :label="$t('ticket.warningDetail.attachment')"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="descriptionModel"
              outlined
              background-color="white"
              required
              :rules="requiredRules"
              dense
              :label="$t('ticket.warningDetail.description')"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <h2>{{ $t("ticket.groceriesSearch.title") }}</h2>

        <v-row>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu"
              v-model="menu"
              dense
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateBegin"
                  :label="$t('ticket.groceriesSearch.fromDate')"
                  :minDate="minDate"
                  :id="'id_' + Math.random()"
                  v-mask="'##/##/####'"
                  dense
                  background-color="white"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-bind="attrs" v-on="on" slot="append">
                    $editcalendar
                  </v-icon>
                </v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                ref="picker"
                v-model="filterData.dateBegin"
                :id="'id_' + Math.random()"
                :max="maxBeginDate"
                :min="minDate | dayjs('format', 'YYYY-MM-DD')"
                first-day-of-week="1"
                type="date"
                no-title
                @input="limitEndDate()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu2"
              v-model="menu2"
              dense
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateEnd"
                  dense
                  :label="$t('ticket.groceriesSearch.toDate')"
                  :id="'id_' + Math.random()"
                  v-mask="'##/##/####'"
                  background-color="white"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-bind="attrs" v-on="on" slot="append">
                    $editcalendar
                  </v-icon>
                </v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                ref="picker"
                v-model="filterData.dateEnd"
                :id="'id_' + Math.random()"
                :min="minEndDate"
                :max="maxEndDate"
                first-day-of-week="1"
                type="date"
                no-title
                @input="limitBeginDate()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              outlined
              background-color="white"
              dense
              v-model="filterData.storeSelected"
              :items="storeList"
              return-object
              item-text="description"
              :label="$t('ticket.groceriesSearch.pdv')"
            ></v-select>
          </v-col>
          <v-col sm="6" class="hidden-xs-only"></v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filterData.minGrossTotal"
              :rules="isNumRules"
              dense
              label="Importo minimo"
              background-color="white"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              v-model="filterData.maxGrossTotal"
              :rules="isNumRules"
              label="Importo massimo"
              background-color="white"
              outlined
              clearable
            ></v-text-field>
          </v-col>

          <v-col cols="12" offset-sm="9" sm="3">
            <v-btn
              :loading="searchOrdersLoading"
              class="w-100"
              color="primary"
              depressed
              height="40"
              @click="searchReceipt"
            >
              {{ $t("orders.ordersFilter.search") }}
            </v-btn>
          </v-col>
        </v-row>

        <div class="ticket-table-list-container mt-5" v-if="receiptList">
          <TicketTableList
            :receiptList="receiptList"
            @receiptSelectedChange="receiptSelectedChange"
          />
        </div>

        <v-alert type="error" v-if="imgErrorString">{{
          $t("tickets.imgTooBigError")
        }}</v-alert>
        <div
          class="buttons-container mt-10 d-flex flex-column flex-sm-row justify-center"
        >
          <!-- <v-btn
            large
            color="primary"
            class="mr-0 mr-sm-5 mt-3 mt-sm-0"
            outlined
            :to="{ path: $routerHistory.previous().path }"
            min-width="250"
            >{{ $t("ticket.backBtn") }}</v-btn
          > -->
          <v-btn
            depressed
            large
            color="primary"
            type="submit"
            min-width="250"
            :disabled="receiptSelected == null"
            >{{ $t("ticket.confirmBtn") }}</v-btn
          >
        </div>
      </v-form>
    </div>
  </v-container>
</template>
<script>
//import CategoryTitle from "@/components/category/CategoryTitle.vue";
import TicketTableList from "@/components/profile/TicketTableList.vue";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import tigrosRegistrationService from "@/service/tigrosRegistrationService";

import get from "lodash/get";
import { isNumber, requiredValue } from "~/validator/validationRules";
import { mask } from "vue-the-mask";

export default {
  name: "Ticket",
  directives: { mask },
  data() {
    return {
      valid: true,
      start: 1,
      searchOrdersLoading: false,
      serviceTypeList: [
        { description: "Punto Vendita", value: 0 },
        { description: "Spesa Online", value: 1 }
      ],
      communicationTypeList: [
        { value: "Mail", description: "E-Mail" }
        // { value: "Cellulare", description: "Cellulare" },
        // { value: "Telefono", description: "Telefono" }
      ],
      ticketTypesList: [
        { value: 1, description: "Prodotti mancanti" },
        {
          value: 2,
          description: "Qualità prodotti freschissimi",
          attachmentRequired: true,
          showLot: true,
          showExpire: true
        },
        {
          value: 3,
          description: "Prodotti danneggiati",
          attachmentRequired: true
        },
        { value: 4, description: "Differenze importo pagato - ordinato" },
        { value: 5, description: "Scortesia personale" },
        {
          value: 6,
          description: "Prodotti con scadenza ravvicinata",
          showLot: true,
          showExpire: true
        },
        {
          value: 7,
          description: "Prodotti scaduti",
          attachmentRequired: true,
          showLot: true,
          showExpire: true
        },
        {
          value: 8,
          description: "Consegnata prodotti in più rispetto l'ordinato"
        },
        { value: 9, description: "Mancanza di prodotti in promozione" },
        {
          value: 10,
          description: "Pagamento elettronico indisponibile - anomalia di cassa"
        },
        { value: 11, description: "Tempi di attesa - ritardo di consegna" },
        { value: 12, description: "Mancanza ricezione mail - sms" },
        { value: 13, description: "Codici sconto e buoni sconto non applicati" }
      ],
      serviceTypeSelected: null,
      communicationTypeSelected: null,
      ticketTypesSelected: null,
      attachmentModel: null,
      descriptionModel: null,
      menu: false,
      menu2: false,
      storeList: [
        {
          code: undefined,
          description: "TUTTI"
        }
      ],
      filterData: {
        dateBegin: null,
        dateEnd: null,
        storeSelected: null,
        minGrossTotal: null,
        maxGrossTotal: null
      },
      receiptList: null,
      receiptSelected: null,
      isNumRules: [isNumber()],
      requiredRules: [requiredValue()],
      imgErrorString: false,
      minBeginDate: this.$dayjs()
        .subtract(3, "month")
        .format("YYYY-MM-DD"),
      minEndDate: this.$dayjs()
        .subtract(3, "month")
        .format("YYYY-MM-DD"),
      maxBeginDate: this.$dayjs().format("YYYY-MM-DD"),
      maxEndDate: this.$dayjs().format("YYYY-MM-DD")
    };
  },
  mixins: [reload, categoryMixin],
  components: { TicketTableList },
  computed: {
    dateBegin: {
      get: function() {
        if (this.filterData.dateBegin) {
          return this.$dayjs(this.filterData.dateBegin).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.filterData.dateBegin = this.$dayjs(value).format("DD/MM/YYYY");
      }
    },
    dateEnd: {
      get: function() {
        if (this.filterData.dateEnd) {
          return this.$dayjs(this.filterData.dateEnd).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.filterData.dateEnd = this.$dayjs(value).format("DD/MM/YYYY");
      }
    },
    minDate() {
      return this.$dayjs()
        .subtract(3, "month")
        .toDate();
    }
  },
  methods: {
    receiptSelectedChange(receiptSelected) {
      this.receiptSelected = receiptSelected;
    },
    async handleSubmit() {
      if (this.$refs.formRef.validate()) {
        //file deve essere minore di 2mb
        if (this.attachmentModel && this.attachmentModel.size / 1024 > 1953) {
          this.imgErrorString = true;
        } else {
          this.imgErrorString = false;

          let appendData = new FormData();
          if (this.receiptSelected) {
            appendData.append(
              "receiptDate",
              this.$dayjs(this.receiptSelected.rif.date).format("DD/MM/YYYY")
            );
            appendData.append(
              "receiptCashRegister",
              this.receiptSelected.rif["cash-register"]
            );
            appendData.append("receiptStore", this.receiptSelected.storeName);
            appendData.append(
              "receiptNumber",
              this.receiptSelected.rif["number"]
            );
          }

          if (this.serviceTypeSelected) {
            appendData.append("serviceTypeId", this.serviceTypeSelected.value);
          }

          if (this.communicationTypeSelected) {
            appendData.append(
              "communicationTypeId",
              this.communicationTypeSelected.value
            );
            appendData.append(
              "communicationType",
              this.communicationTypeSelected.description
            );
          }

          if (this.attachmentModel) {
            appendData.append("file", this.attachmentModel);
          }
          if (this.ticketTypesSelected) {
            appendData.append("ticketTypeId", this.ticketTypesSelected.value);
            appendData.append(
              "ticketType",
              this.ticketTypesSelected.description
            );
          }
          if (this.descriptionModel) {
            appendData.append("description", this.descriptionModel);
          }

          let res = await tigrosRegistrationService.sendTicket(appendData);

          if (res && res.status == 0) {
            this.$router.push({
              name: "TicketSentSuccessfull"
            });
          }
        }
      }
    },
    getFormattedDate(date) {
      let d = this.$dayjs(date).format("DD/MM/YYYY");
      return d;
    },
    async searchReceipt() {
      let res = await tigrosRegistrationService.getReceiptList(
        this.getFormattedDate(this.filterData.dateBegin),
        this.getFormattedDate(this.filterData.dateEnd),
        this.filterData.minGrossTotal,
        this.filterData.maxGrossTotal,
        this.filterData.storeSelected,
        this.start - 1,
        false,
        false
      );

      this.receiptList = get(res, "receipts", null);
    },
    async initStores() {
      let res = await tigrosRegistrationService.getStores();

      if (res.stores && res.stores.length > 0) {
        this.storeList = res.stores;
      }
    },
    limitBeginDate() {
      this.menu2 = false;
      let splitEndDate = this.dateEnd.split("/");
      let endDateParsed = new Date(
        splitEndDate[2],
        splitEndDate[1] - 1,
        splitEndDate[0]
      );
      endDateParsed = this.$dayjs(endDateParsed);
      const minBeginDate = this.$dayjs(endDateParsed)
        .subtract(3, "month")
        .format("YYYY-MM-DD");
      this.minBeginDate = minBeginDate;
      this.maxBeginDate = this.$dayjs(endDateParsed).format("YYYY-MM-DD");
      console.log("NEW BEGIN", this.minBeginDate, this.maxBeginDate);
    },
    limitEndDate() {
      this.menu = false;
      let splitBeginDate = this.dateBegin.split("/");
      let dateBeginParsed = new Date(
        splitBeginDate[2],
        splitBeginDate[1] - 1,
        splitBeginDate[0]
      );
      this.minEndDate = this.$dayjs(dateBeginParsed);
      this.minEndDate = this.$dayjs(this.minEndDate).format("YYYY-MM-DD");
      this.maxEndDate = this.$dayjs().format("YYYY-MM-DD");
      console.log("NEW END", this.minEndDate, this.maxEndDate);
    }
  },
  mounted() {
    this.serviceTypeSelected = this.serviceTypeList[0];
    this.communicationTypeSelected = this.communicationTypeList[0];
    this.ticketTypesSelected = this.ticketTypesList[0];
    this.filterData.dateBegin = this.$dayjs()
      .subtract(3, "month")
      .format("YYYY-MM-DD");
    this.filterData.dateEnd = this.$dayjs().format("YYYY-MM-DD");
    this.filterData.storeSelected = this.storeList[0];
    this.initStores();
  }
};
</script>
