<template>
  <div class="generic-list ticket-table-list-container">
    <v-row
      no-gutters
      class="row-header text-body-2 py-5 primary--text grey lighten-2 rounded-sm"
    >
      <v-col cols="4" offset-sm="1">
        <strong>{{ $t("tickets.header.date") }}</strong>
      </v-col>

      <v-col cols="4">
        <strong>{{ $t("tickets.header.pdv") }}</strong>
      </v-col>

      <v-col cols="4" sm="3" md="3">
        <strong>{{ $t("tickets.header.total") }}</strong>
      </v-col>

      <!-- <v-col cols="0" sm="2" md="2" class="hidden-xs-only">
        <strong>{{ $t("tickets.header.points") }}</strong>
      </v-col> -->
    </v-row>

    <v-list class="mt-4 ticket-table-list">
      <template v-if="receiptList.length > 0">
        <v-list-item
          class="py-0"
          v-for="receipt in receiptList"
          :key="receipt.id"
        >
          <v-row no-gutters align="center" class="text-caption text-sm-body-2">
            <v-col cols="1">
              <v-checkbox
                v-model="receiptSelected"
                :value="receipt.rif.number"
                :disabled="
                  receiptSelected.length > 0 &&
                    receiptSelected[0] != receipt.rif.number
                "
                @change="receiptSelectedChange(receipt)"
              ></v-checkbox>
            </v-col>
            <!-- Data -->
            <v-col cols="4">
              <div class="font-weight-bold primary--text">
                {{ $dayjs(receipt.rif.date).format("D MMMM YYYY") }}
              </div>
            </v-col>

            <!-- Punto vendita -->
            <v-col cols="4">
              <div class="font-weight-bold primary--text">
                <span class="text-capitalize">{{ receipt.storeName }} </span>
              </div>
            </v-col>

            <!-- Totale spesa -->
            <v-col cols="3">
              <div class="d-flex align-center default--text font-weight-bold">
                <strong> {{ $n(receipt.total, "currency") }}</strong>
              </div>
            </v-col>

            <!-- punti accumulati -->
            <!-- <v-col cols="3" sm="1" md="1" lg="1" class="hidden-xs-only">
              <strong> {{ receipt.points }}</strong>
            </v-col> -->
          </v-row>
        </v-list-item>
      </template>
      <v-list-item v-else>
        <v-list-item-content class="pl-2">
          {{ $t("tickets.noOrderFound") }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.ticket-table-list-container {
  .active {
    background-color: var(--v-primary-lighten2) !important;
  }
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
  .v-list.ticket-table-list {
    .v-list-item {
      background-color: var(--v-grey-lighten2);
      &:nth-child(odd) {
        background: white;
      }
    }
  }
}
</style>
<script>
export default {
  name: "TicketTableList",
  props: {
    receiptList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      receiptSelected: []
    };
  },
  computed: {},
  methods: {
    receiptSelectedChange(receipt) {
      if (this.receiptSelected.length > 0) {
        this.$emit("receiptSelectedChange", receipt);
      } else {
        this.$emit("receiptSelectedChange", null);
      }
    }
  },
  mounted() {}
};
</script>
